import React from "react";

const Logo = () => {
    // const isMobile = useMediaQuery('(max-width: 850px)')
    return <>
        <a className="navbar-brand" href="https://www.afrisends.com" aria-label="Unify">
            <img className="navbar-brand-logo" src={"/assets/img/logo-main.png"} alt="LogoAFRISENDS"/>
        </a>
    </>
}

export default Logo;
