    import React from "react";
import Logo from "./Logo";
import MenuContent from "./MenuContent";
import {Helmet} from "react-helmet";

const Header = ({title}:{title: string}) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/css/vendor.min.css" />
                <link rel="stylesheet" href="/assets/css/theme.min.css?v=1.0" />
                <script src="/assets/js/vendor.min.js"/>
                <script src="/assets/js/theme.min.js"/>
            </Helmet>
            <header id="header"
                  className="navbar navbar-expand-lg navbar-end navbar-light navbar-absolute-top navbar-show-hide"
                  data-hs-header-options='{"fixMoment": 0, "fixEffect": "slide"}'>
            <div className="container">
                <nav className="js-mega-menu navbar-nav-wrap">
                    <Logo/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-default"><i className="bi-list"/></span>
                        <span className="navbar-toggler-toggled"><i className="bi-x"/></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className="navbar-absolute-top-scroller">
                            <MenuContent/>
                        </div>
                    </div>
                </nav>
            </div>
            </header>
        </>

    );
}
export default Header;
