import React from "react";
import Layout from "../components/layout/Layout";
import AboutContent from "../components/AboutContent";
const About = () => {
    return <>
        <Layout title={'A propos de nous'}>
            <AboutContent/>
        </Layout>
    </>
}
export default About;