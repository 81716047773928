import React from "react";
import {getConnected, getConnectedToken, getInitiales, logoutOut} from "../../util/helper";
import {useMediaQuery} from "../../util/media-query";

const MenuContent = () => {
    let connected = getConnected();
    let connectedToken = getConnectedToken();
    const isMobile = useMediaQuery('(max-width: 850px)');
    return <>
        <ul className="navbar-nav nav-pills">

            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="https://www.afrisends.com" role="button" aria-expanded="false">Accueil</a>
            </li>

            {/*<li className="nav-item"><a className="nav-link" href="/about" role="button" aria-expanded="false">Notre
                histoire</a></li>*/}
           {/* <li className="nav-item"><a className="nav-link" href="/careers" role="button"
                                        aria-expanded="false">Carrières</a></li>*/}
            <li className="nav-item"><a className="nav-link" href="/contact" role="button" aria-expanded="false">Nous contacter</a></li>

            {/*<li className="nav-item">
                <a className="nav-link position-relative" href="/wari" role="button" aria-expanded="false">Cashback
                    {isMobile?
                        <span>&nbsp;&nbsp;&nbsp;<span className="badge badge rounded-pill bg-black">Nouveau</span></span>
                        :
                        <span style={{fontSize: '8px'}}
                           className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-black">Nouveau
                            <span className="visually-hidden">Nouveau</span>
                        </span>}
                </a>
            </li>*/}
            <li className="nav-item"><a className="nav-link" href="/tracking" role="button" aria-expanded="false">Tracking</a></li>
            <li className="nav-item"><a className="nav-link" href="https://www.afrisends.com/faq" role="button" aria-expanded="false">FAQ</a>
            </li>
            {/*<li className="nav-item"><a className="nav-link" href="/bonplan" role="button" aria-expanded="false">Bon Plan</a></li>*/}
            {
                connected && connectedToken ?
                    <>
                        {/* Dropdown */}
                        <div className="hs-has-sub-menu connected-user"
                             style={{marginLeft:10, marginBottom:isMobile?60:0}}>
                            <span className="hs-mega-menu-invoker"
                                  id="headerWithMegaMenuDropdownResponsive"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                            >{getInitiales(connected.fullName)}</span>
                            <div id="navProfilDropDown"
                                 className="hs-sub-menu dropdown-menu"
                                 aria-labelledby="headerWithMegaMenuDropdownResponsive"
                            >
                                <a className="dropdown-item" href="/app/profile">
                                    <i className="bi-person dropdown-item-icon"/> Mon profile
                                </a>
                                <a className="dropdown-item" href="#" onClick={logoutOut}>
                                    <i className="bi-box-arrow-right dropdown-item-icon"/> Se déconnecter
                                </a>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <li className="nav-item ms-lg-auto">
                            <a className="btn btn-ghost-dark me-2 me-lg-0" href="/app/login">Connexion</a>
                            <a className="btn btn-dark d-lg-none" href="/app/register">S'inscrire</a>
                        </li>

                        <li className="nav-item">
                            <a className="btn btn-dark d-none d-lg-inline-block" href="/app/register">S'inscrire</a>
                        </li>
                    </>
            }
        </ul>
    </>
}
export default MenuContent;
