import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({children, title}: any) => {

    return <>
        <Header title={`Afrisends | ${title}`}/>
        <main id="content" role="main">
            {children}
        </main>
        <Footer/>
    </>
}

export default Layout;
