import React from "react";

const AboutContent = () => {

    return <>
        <div>
            <div className="container content-space-t-3 content-space-t-lg-4">
                <div className="w-lg-65 text-center mx-lg-auto">
                    <h2>Présentation</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 gx-7 p-4">
                    <div className="col">
                        <p>Afrisends est né du besoin d’offrir aux africains un moyen simple, professionnel et formel
                            de se faire livrer des marchandises disponibles en France, en Turquie et en Chine.</p>
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 gx-0 mt-5">
                            <div className="col text-center">
                                <div className="mx-auto" >
                                    <img className="img-fluid rounded-3 mb-2" src="/assets/img/zeinab.jpeg"
                                         alt="Image Description" style={{height: 350}}/>

                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <p style={{fontStyle: 'italic'}} className="mt-5">« Avec le boom de l’e-commerce et la multiplication des centres d’approvisionnement, les africains doivent pouvoir se procurer les produits de leur choix à un prix compétitif et dans les meilleurs délais »</p>
                                    <h5 className="mb-1 mt-4">Zeïnabou sidibé</h5>
                                    <span className="d-block">Dirigeante d’Afrisends</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="mt-5">Originaire de Bamako et élevée dans une famille ayant une forte culture entrepreneuriale, Zeinabou Sidibé n’attend pas que la solution à ce besoin vienne d’ailleurs. En 2019, elle se lance à la recherche de partenaires fiables et propose ses services à son entourage. La demande étant forte, elle trouve rapidement ses premiers clients et multiplie les expéditions sur les axes Paris {'>'} Bamako , Paris {'>'} Dakar.</p>
                        </div>
                    </div>
                    <div className="col">
                        <p>3 ans plus tard, Afrisends propose une multitude de services adaptés au contexte Ouest-Africain :

                        </p>
                        <br/>
                        <p>
                            Services pour particuliers : <br/>
                            - Personal Shopping <br/>
                            - Achat en ligne <br/>
                            - Service déménagement France {'>'} Afrique <br/>
                        </p>
                        <br/>
                        <p>
                            Services pour professionnels : <br/>
                            - Sourcing et achat <br/>
                            - Expédition (Maritime et aérienne) et logistique <br/>
                            - Dédouanement et livraison au dernier mile.
                        </p>
                        <br/>
                        <p>
                            En 2023, Afrisends mise sur un solide réseau mondial de partenaires en logistique, une base de donnée de centaines de fournisseurs et des solutions technologiques innovantes pour ouvrir les portes du commerce international à ses clients professionnels et particuliers en Afrique de l’Ouest.
                        </p>
                        <p>Afrisends vous ouvre le monde !</p>
                    </div>
                </div>
                <div className="w-lg-65 text-center mx-lg-auto mt-sm-1 mb-sm-1 mt-lg-5 mb-lg-5 mt-xl-5 mb-xl-5 mt-md-5 mb-md-5">
                    <h2>Notre équipe</h2>
                </div>
                <div className="position-relative bg-img-center min-vh-100"
                     style={{background: `url(/assets/img/team.png) no-repeat center/100%`}}>
                </div>
            </div>

            <div className="container content-space-b-2 content-space-b-lg-3 mt-2">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <figure className="mb-7">
                        <img className="img-fluid" src="/assets/svg/illustrations/plane.svg" alt="Image Description"
                             style={{width: "10rem"}}/>
                    </figure>
                    <h2 className="mb-7">Afrisends vous ouvre le monde !</h2>
                </div>
            </div>
        </div>
    </>
}

export default AboutContent;
