import React from "react";
import "../../../static/assets/css/Footer.css";
// @ts-ignore
import img from '../../images/logo-second.jpg';

const Footer = () => {
    let year = new Date().getFullYear();
    return <>
        <a href="https://api.whatsapp.com/send?phone=33767622662"
           className="float shadow-xl" target="_blank">
            <i className="bi-whatsapp my-float"/>
        </a>
        <footer className="footer-distributed bg-white">
            <div className="container">
                <div className="footer-left ">
                    <h3>
                        <img className="navbar-brand-logo" src={img} alt="Logo AFRISENDS"/>
                    </h3>
                    <div className="col-md-auto">
                        <div className="d-grid d-lg-flex gap-3 btn-test-simulator">
                            <a className="btn btn-primary" href="/app/simulator">Testez notre simulateur &nbsp;<i
                                className="bi-box-arrow-up-right"/></a>
                        </div>
                    </div>
                </div>
                <div className="footer-center">
                    <div>
                        <i className="bi-pin-map"/>
                        <p><span>Afrisends</span> ACI 2000, Bamako, Mali.</p>
                    </div>
                    <div>
                        <i className="bi-telephone"/>
                        <p>+223 79 64 79 79</p>
                    </div>
                    <div>
                        <i className="bi-whatsapp"/>
                        <p>+33 76 762 26 62</p>
                    </div>
                    <div>
                        <i className="bi-envelope"/>
                        <p><a href="mailto:commande@afrisends.com">commande@afrisends.com</a></p>
                    </div>
                    <div>
                        <i className="bi-file-earmark-text"/>
                        <p><a href="/terms" role="button" aria-expanded="false">Conditions Générale de vente</a></p>
                    </div>
                    <div>
                        <i className="bi-person-rolodex"/>
                        <p><a className="text-white" href="/careers" role="button" aria-expanded="false">Carrières</a></p>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-company-about">
                        <span>Informations utiles</span>
                        <ul className="footer-company-about-ul">
                            <label>Horaires</label>
                            <li>Lundi - Vendredi : 9h-17h</li>
                            {/*<li>Samedi: 11H - 17h</li>*/}
                        </ul>
                        {/*<ul className="footer-company-about-ul">
                            <label>Horaires De Réception Colis</label>
                            <li>Lundi - Vendredi: 9h-17h</li>
                        </ul>*/}
                    </div>
                    <div className="footer-icons">
                        <a href="#" target="_blank"><i className="bi-whatsapp"/></a>
                        <a href="https://www.facebook.com/afrisends" target="_blank"><i className="bi-facebook"/></a>
                        <a href="https://twitter.com/afrisends" target="_blank"> <i className="bi-twitter"/></a>
                        <a href="https://www.instagram.com/afrisends/" target="_blank"><i className="bi-instagram"/></a>
                        <a href="#" target="_blank"><i className="bi-linkedin"/></a>
                    </div>
                </div>
            </div>
        </footer>
        <div className="footer-company-name">
            &copy; {year} Afrisends . Tout droit réservé
        </div>
    </>
}

export default Footer;
